.displayOnClickTextField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.displayOnClickTextField .label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.displayOnClickTextField .inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px auto;
}

.displayOnClickTextField .input {
    flex-grow: 1;
    padding: 0.5rem;
    border: 1px solid #000000;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    outline: none;
}

.displayOnClickTextField .displayButton {
    margin-left: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #000000;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    outline: none;
    cursor: pointer;
}