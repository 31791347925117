body {
    background: linear-gradient(0deg, #e3e3e3, #a3a3a3);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.pre-checkout-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    font-size: 0.9em;
    margin-inline-end: 10px;
}

.pre-checkout-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 200px 90px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 10px;
    max-width: 80%;
    height: 90%;
}

h1 {
    font-size: 2em;
    margin: 0.4em 0;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group {
    width: 100%;
}

input[type='text'] {
    width: 60%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;

}

input[type='color'] {
    width: 50%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button {
    display: block;
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.button:hover:enabled {
    background-color: #0056b3;
}

.button:disabled {
    background-color: #eee;
    color: #ccc;
    cursor: not-allowed;
}

input:disabled {
    background-color: #eee;
    color: #ccc;
    cursor: not-allowed;
}

.design-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.design-button-group .button {
    margin-inline-end: 5px;
}

.slug-input {
    display: flex;
    align-items: center;
}

.slug-input input {
    margin-right: 10px;
}

.align-right {
    text-align: right;
}

.sections {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    margin: 0;
    margin-bottom: 20px;
    max-width: 90%;
}

.design-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: #eeeeee;
    border-radius: 8px;
    padding: 20px;
    margin: 10px 20px;
    width: max-content;
    max-width: 100%;
}

.configuration-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: #eeeeee;
    border-radius: 8px;
    padding: 20px;
    margin: 10px 20px;
    width: max-content;
    max-width: 80%;
}

@media (max-width: 768px) {
    .sections {
        flex-direction: column;
    }

    .pre-checkout-container {
        flex-direction: column;
    }
}

.price {
    font-size: 1.2em;
}

.explanation {
    width: 20em;
    max-width: 100%;
}

.checkoutButton {
    padding: 20px 0;
}

.linkLabel {
    display: inline-block;
}

.linkButton {
    background: none!important;
    border: none;
    padding: 0!important;
    display: inline !important;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.gradientPreviewButton {
    background: #000;
    border: 0px solid #ccc;
    color: #fff;
    font-weight: bold;
    /*Add elevation*/
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    text-shadow: 1px 1px 12px rgba(0, 0, 0, 0.9);
}

.gradientPreviewButton:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    /*add dark overlay to background but keep existing color*/
    

}

.gradientPreviewButton:active {
    /**/
    -webkit-box-shadow: inset 0px 0px 10px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 10px #c1c1c1;
          box-shadow: inset 0px 0px 10px #c1c1c1;
   outline: none;
}