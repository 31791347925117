button.textButton {
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    text-align: start;
  }
  
button.textButton:focus {
  outline: none;
}

button.textButton:hover {
  text-decoration: none;
}