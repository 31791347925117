.successBox {
    /*Div to wrap p elements for a "successful order"/thank you page*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 20px min(10%, 200px);

    /* background-color: #eeeeee33; */
    background-color: #00bf0033;
    border-radius: 8px;
}

.betterA {
    /*Better looking link*/
    color: #ffffff;
    font-weight: bold;
}