.background {
    height: 100%;
    min-height: 100vh;


    /* Add the blur effect */
    filter: blur(7px);
    -webkit-filter: blur(7px);

    background-color: #f5f5f5;
    background-repeat: repeat-y;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.backgroundOverlay {
    position: fixed;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100vw;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: visible;
}

.container {
    z-index: 3;
    position: static;
    width: inherit;
    height: max-content;
    height: inherit;
}

/* Position text in the middle of the page/image */
.frontpage {
    /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    height: 100%;
    text-align: center;
}

.frontpage .header {
    padding: 20px;
    padding-bottom: 0;
    font-weight: 500;
}

.frontpage .header .tagline {
    font-family: 'Courgette', sans-serif;
    font-style: italic;
    font-size: 1.3em;
    margin-top: 0;
    margin-bottom: 40px;
}

.frontpage .header .description {
    max-width: 600px;
    margin: 20px auto;
    line-height: 1.5em;
    margin-top: 0;
}


.frontpage .header h1 {
    font-family: 'Courgette', sans-serif;
    font-size: 5em;
    letter-spacing: 0.03em;
    margin-bottom: 0;
}

.actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    font-size: 0.9em;
    margin-inline-end: 10px;
}

.button.orderButton {
    background-color: #ffffff;
    color: #000;
    font-weight: bold;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    max-width: 50%;
    height: 90%;
}

.button.orderButton:hover:enabled {
    background-color: #e3e3e3;
    color: #000;
}

.button.orderButton:active:enabled {
    background-color: #e3e3e3;
    color: #000;
}