@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 100;
}

.overlayContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    overflow-y: scroll;
}

.overlayHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.overlayHeader h1 {
    font-size: 1.5rem;
    font-weight: 500;
}

.overlayBody {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
}

.overlayBody h2 {
    font-size: 1.20rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.overlayBody p {
    margin-bottom: 10px;
}

.closeButton {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
}

.closeButton:hover {
    color: rgb(0, 0, 0, 0.5);
}