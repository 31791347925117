.radio-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.radio-button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    padding: 8px 16px;
    margin: 0 8px 0 0;
    transition: all 0.2s ease-in-out;
}

.radio-button:disabled {
    background-color: #eee;
    color: #ccc;
    cursor: not-allowed;
}

.radio-button:hover:enabled {
    background-color: #eee;
}

.radio-button.selected {
    background-color: #333;
    color: #fff;
}

.radio-button.selected:disabled {
    background-color: #555;
    color: #fff;
}

.radio-button.selected:hover:enabled {
    background-color: #222;
}

.radio-button:focus {
    outline: none;
}

@media (max-width: 768px) {
    .radio-button-container {
        flex-direction: column;
    }
}